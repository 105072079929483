import { Typography } from "@mui/material";
import { NextPage } from "next";
import { LoginLayout } from "../layouts/pages/LoginLayout";

const Login: NextPage = () => {

  return (
    <LoginLayout title="Login" displayLoginButton displaySSO>
      <Typography component="h1" variant="h3">
        Log in to Reclaim.ai
      </Typography>
    </LoginLayout>
  );
};

export default Login;
